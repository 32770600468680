import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import shell1 from '../assets/sunglass.png';
import shell2 from '../assets/moreCoins.png';
import note1 from '../assets/PIN4-.png';
import note2 from '../assets/PIN5-.png';
import note3 from '../assets/PIN6-.png';
import faq from '../assets/faq.png';


const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div>
                <img class="faqH" src={faq} />
                <div className='blueBg'>
                    <div class="aboutMain--2">
                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />
                    </div>

                    <div className='aboutMain--2Mob'>

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />


                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                        <img className='leftI' src={note1} />
                        <img className='middle' src={note2} />
                        <img className='rightI' src={note3} />

                    </div>

                </div>
                <p></p>
            </div>
        )
    }
}

export default About;

